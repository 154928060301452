import {useLocation} from '@reach/router';
import Image from 'components/image';
import Layout from 'components/layout';
import SEO from 'components/seo';
import SlideshowAdvantage from 'components/slideshow_advantage';
import {Link} from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, {useEffect} from 'react';
import Style from 'styles/advantage.module.scss';

const Advantage = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, [location.hash]);

  return (
    <Layout>
      <SEO title='Advantage|About' path='about/advantage' />
      <section className={Style.advantageIntro}>
        {<Image className={Style.advantageIntro__bgImage} filename='advantage_header.jpg' />}
        <div className={Style.advantageIntro__inner}>
          <h1 className={Style.advantageIntro__title}>ADVANTAGE</h1>
          <div className={Style.advantageIntro__subTitle}>
            <h2>
              新しい技術を吸収し、
              <br />
              形にできる環境がある
            </h2>
          </div>
          <div className={Style.advantageIntro__txt}>
            <p>
              イーゼは、取引先のさまざまなプロジェクトを通して得たノウハウや、新しい技術などを自社開発にも取り入れていける姿勢を目指しています。
            </p>
            <p>
              好奇心旺盛で新しい技術を学ぶことが好きな人、物事を多角的な視点で捉え独創的なアイディアを生み出すのが得意な人、自発的にアクションを起こし新しいプロジェクトを立ち上げてみたい人...一緒にワクワクする未来を考えてみませんか？
            </p>
          </div>
        </div>
      </section>

      <section className={Style.advantageProduct} id="product">
        <h2 className={Style.advantageProduct__header}>Product</h2>
        <div className={Style.advantageProduct__inner}>
          <div className={Style.advantageProduct__item_01}>
            <div className={Style.advantageProduct__txt}>
              <p>
                メインのSES、受託事業以外に自社システムの開発も行っています。 ECサイトのショッピングカートシステム「
                <Link className={Style.advantageProduct__link} to='/comachicart/'>
                  comachicart(コマチカート)
                </Link>
                」を軸に、社内で運用中の勤怠・有給管理システムなどを設計から運用まで自社の社員だけで行っています。
                comachicart(コマチカート)をはじめ、自社システムの多くが社員のアイデアや要望を形にしたものです。
              </p>
            </div>
            <Link to="/comachicart/">
              <div
                className={Style.advantageProduct__imgs}
                data-sal='fade'
                data-sal-duration='300'
                data-sal-delay='300'
                data-sal-easing='ease-out'
              >
                <Image filename='bnr_advantage_comachi.png' className={Style.advantageProduct__imgItem} />
              </div>
            </Link>
          </div>
          <div className={Style.advantageProduct__item_02}>
            <SlideshowAdvantage />
          </div>
        </div>
      </section>

      <section className={Style.advantageWorkshop} id="workshop">
        <h2 className={Style.advantageWorkshop__header}>Workshop</h2>
        <div className={Style.advantageWorkshop__inner}>
          <div className={Style.advantageWorkshop__txt}>
            <p>
              月に一度少人数のグループに分かれてワークショップを開催しています。
              そこでは、普段の業務では携わらない分野も含め、各々が学びたいことを持ち寄って共に学び、より効率的にスキルを伸ばしていきます。
            </p>
            <p>
              また、スキルを伸ばすだけではなく、日頃の業務でよりうまく連携をとっていくためのチームビルディングも目的としています。
              日頃の業務では携わらない分野のことを扱うこともあり、視野の広まりや知識の取得を通じて自身の成長を加速させていくことができます。
            </p>
          </div>
          <div className={Style.advantageWorkshop__imgItems}>
            <div
              className={Style.advantageWorkshop__imgItem_01}
              data-sal="fade"
              data-sal-duration="300"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
              <Image filename="advantage_workshop4.png" style={{ height: "100%" }} />
            </div>
            <div className={Style.advantageWorkshop__imgItem_02}>
              <div
                className={Style.advantageWorkshop__imgHolder}
                data-sal="fade"
                data-sal-duration="300"
                data-sal-delay="300"
                data-sal-easing="ease-out"
              >
                <Image filename="advantage_workshop2.jpg" className={Style.advantageWorkshop__img} />
                <Image filename='advantage_workshop5.png' className={Style.advantageWorkshop__img} />
              </div>
            </div>
          </div>
          <div className={Style.advantageWorkshop__messages}>
            <div className={Style.advantageWorkshop__message}>
              <h3 className={Style.advantageWorkshop__messageTitle}>Skills</h3>
              <p>
                AWSをはじめとしたクラウドサービスをハンズオン形式で触ってみたり、モダンな技術やツールをチーム内で共有することで各々のスキルUPを図っています。
              </p>
            </div>
            <div className={Style.advantageWorkshop__message}>
              <h3 className={Style.advantageWorkshop__messageTitle}>KPT</h3>
              <p>
                KPT法を用いて、客先や自社での個々の課題を共有し、改善すべきことを明確化することで社員の成長に繋げていきます。
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={Style.advantageMbo} id="mbo">
        <h2 className={Style.advantageMbo__header}>Mbo</h2>
        <div className={Style.advantageMbo__inner}>
          <div className={Style.advantageMbo__txt}>
            <p>
              社員、組織それぞれがが成長していくために目標管理制度を設けています。
              半年ごとに目標設定の機会を設け、自己実現と組織への貢献を軸に、個々の能力に見合った課題を設定します。
              また、設定した目標の達成度合に応じて昇給を決定します。
            </p>
          </div>
          <div className={Style.advantageMbo__items}>
            <div className={Style.advantageMbo__item}>
              <div
                className={Style.advantageMbo__itemImg}
                data-sal='fade'
                data-sal-duration='300'
                data-sal-delay='300'
                data-sal-easing='ease-out'
              >
                <Image filename='advantage_mbo1.jpg' />
              </div>
              <div className={Style.advantageMbo__itemTxt}>
                <h2 className={Style.advantageMbo__itemTitle}>Vision</h2>
                <p>
                  社員一人一人が「将来どんなエンジニアになりたいのか」というビジョンを大切にしており、明確なロードマップを一緒に描いていくことでなりたい自分を実現できるようサポートします。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Advantage;
