import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Image from "components/image";
import "styles/components/slideshow_advantage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image3 from "images/advantage_product3.jpg";
import image2 from "images/advantage_product2.jpg";
import image1 from "images/advantage_product1.jpg";

const SlideshowAdvantage = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const images = [image3, image2, image1];
  const settingsMain = {
    className: "slider-main",
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    arrows: false,
    autoplay: true,
    speed: 500,
    asNavFor: nav2,
    ref: slider => setSlider1(slider),
  };
  const settingsThumbs = {
    className: "slider-thumbs",
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    fade: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "0px",
    asNavFor: nav1,
    ref: slider => setSlider2(slider),
  };

  return (
    <>
      <Slider {...settingsMain}>
        {images.map((image, index) => (
          <div className="slider-item" key={index}>
            <img src={image} />
          </div>
        ))}
      </Slider>
      <Slider {...settingsThumbs}>
        {images.map((image, index) => (
          <div className="slider-thumb-item" key={index}>
            <img src={image} />
          </div>
        ))}
      </Slider>
    </>
  );
};

export default SlideshowAdvantage;
